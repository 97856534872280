import imgSharePL from "../images/common/getinweb-share-pl.png";
import imgShareEN from "../images/common/getinweb-share-en.png";

export const getShareImage = lang => {
  switch (lang) {
    case "en":
      return imgShareEN;
    default:
      return imgSharePL;
  }
};
