import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { NavElem } from "../Header/Navigation";
import SocialMedia from "../common/SocialMedia";
import { getHrefs } from "../../utils/langService";

import imgGetinweb from "../../images/common/getinweb.svg";

const Footer = ({ lang, t, posts, pathname }) => {
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setPhone(process.env.PHONE);
  }, [setPhone]);

  const { pages } = getHrefs(lang);

  return (
    <footer className="Footer">
      <div className="container">
        <div className="Footer__top">
          <div className="Footer__column">
            <Link className="link-flex Footer__logo" to={`/${lang}/`}>
              <img src={imgGetinweb} alt="Getinweb" />
            </Link>
            <p>{t.data.address}</p>
            <br />
            <p>{`${t.data.taxId} ${process.env.VATID}`}</p>
            <p>{`${t.data.regon} ${process.env.REGON}`}</p>
          </div>

          <div className="Footer__column">
            <h4 className="Footer__column__title">{t.menu.title}</h4>
            <ul className="Footer__column__list">
              <NavElem name={t.menu.home} to={`/${lang}/`} pathname={pathname} />
              {/* <NavElem name={t.menu.blog} to={`/pl/${getHrefs("pl").pages[2]}/`} pathname={pathname} /> */}
              <NavElem name={t.menu.about} to={`/${lang}/${pages[0]}/`} pathname={pathname} />
              <NavElem name={t.menu.contact} to={`/${lang}/${pages[3]}/`} pathname={pathname} />
              <NavElem name={t.menu.estimate} to={`/${lang}/${pages[4]}/`} pathname={pathname} />
              <NavElem name={t.menu.career} to={`/pl/${getHrefs("pl").pages[1]}/`} pathname={pathname} />
              <NavElem name={t.menu.press} to={`/${lang}/${pages[8]}/`} pathname={pathname} />
            </ul>
          </div>

          <div className="Footer__column">
            {/* <h4 className="Footer__column__title">{t.blog.title}</h4>
            <ul className="Footer__column__list">
              {posts.map((elem, index) => (
                <NavElem key={index} name={elem.title} to={`/pl/${getHrefs("pl").pages[2]}/${elem.shortName}/`} pathname={pathname} />
              ))}
            </ul> */}
          </div>

          <div className="Footer__column"></div>

          <div className="Footer__column">
            <h4 className="Footer__column__title">{t.contact.title}</h4>
            <ul className="Footer__column__list">
              <li>
                <a href={`mailto:${t.contact.email}`}>{t.contact.email}</a>
              </li>
              {lang === "pl" && !!phone && (
                <li>
                  <a href={`tel:${phone}`}>{phone}</a>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="Footer__bottom">
          <div className="Footer__copy">
            <p>&copy; {new Date().getFullYear()} Getinweb. All rights reserved.</p>
            <Link to={`/${lang}/${pages[5]}/`}>{t.other.privacyPolicy}</Link>
            <Link to={`/${lang}/${pages[6]}/`}>{t.other.terms}</Link>
          </div>

          <SocialMedia />
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired
};

export default Footer;
