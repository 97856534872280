import { useEffect, useCallback } from "react";

export const useOutsideClick = (ref, callback) => {
  const handleClickCallback = useCallback(
    e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickCallback);

    return () => {
      document.removeEventListener("click", handleClickCallback);
    };
  });
};
