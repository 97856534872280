import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { langsList, changePath } from "../../utils/langService";

import imgDownArrow from "../../images/common/down-arrow.svg";

const Lang = ({ lang, pathname, withoutLangChange }) => {
  const secondLang = lang === langsList[1] ? langsList[0] : langsList[1];
  const langRef = useRef();
  const [opened, setOpened] = useState(false);

  useOutsideClick(langRef, () => closeMenu());

  const toggleMenu = () => {
    setOpened(!opened);
  };

  const closeMenu = () => {
    if (!!opened) setOpened(false);
  };

  const changeLang = elem => {
    if (!!withoutLangChange) return;

    const change = changePath({ lang: elem, pathname });
    if (pathname !== change) navigate(change);
  };

  return (
    <div className="Lang" ref={langRef}>
      <button className={`Lang__btn Lang__btn--main ${!!opened ? "Lang__btn--opened" : ""}`} onClick={toggleMenu}>
        {lang} <img className="Lang__btn__arrow" src={imgDownArrow} alt="toggle" />
      </button>

      {!!opened && (
        <div className="Lang__menu">
          <button className="Lang__btn" onClick={() => changeLang(secondLang)} disabled={!!withoutLangChange}>
            {secondLang}
          </button>
        </div>
      )}
    </div>
  );
};

Lang.propTypes = {
  lang: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  withoutLangChange: PropTypes.bool
};

export default Lang;
