import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../styles/main.scss";
import HelmetScripts from "../components/HelmetScripts";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CookieBar from "../components/common/CookieBar";
import { getCookie, setCookie } from "../utils/cookie";
import OrderService from "../services/OrderService";
import OrderLeaf from "../components/common/OrderLeaf";

const Layout = ({ children, t, servicesT, posts, lang, pathname, withoutOrderLeaf, withoutLangChange }) => {
  const [cookieBar, setCookieBar] = useState(false);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    if (!getCookie("ca")) setCookieBar(true);

    setOrder(OrderService.getOrder());
  }, []);

  const closeCookieBar = () => {
    setCookie("ca", true, 365);
    setCookieBar(false);
  };

  return (
    <>
      <HelmetScripts />

      <Header lang={lang} pathname={pathname} t={t.header} servicesT={servicesT} withoutLangChange={withoutLangChange} />

      <main>
        <div className="wrapper">{children}</div>

        {!!order.length && !withoutOrderLeaf && <OrderLeaf t={t.orderLeaf} lang={lang} quantity={order.length} />}

        {cookieBar && <CookieBar t={t.cookieBar} lang={lang} close={closeCookieBar} />}
      </main>

      <Footer lang={lang} t={t.footer} posts={posts} pathname={pathname} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.object.isRequired,
  servicesT: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  withoutOrderLeaf: PropTypes.bool,
  withoutLangChange: PropTypes.bool
};

export default Layout;
