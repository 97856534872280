import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getHrefs } from "../../utils/langService";

import imgClose from "../../images/common/close-white.svg";

export const MegaMenuItem = ({ name, to }) => (
  <li className="MegaMenu__item">
    <Link to={to}>{name}</Link>
  </li>
);

MegaMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

const MegaMenu = ({ lang, servicesT, opened, close }) => {
  const { services } = getHrefs(lang);

  return (
    <div className={`MegaMenu ${opened ? "MegaMenu--mobileOpen" : ""}`}>
      <div className="MegaMenu__close">
        <button onClick={close} className="MegaMenu__close__btn">
          <img src={imgClose} alt="Zamknij" />
        </button>
      </div>

      <div className="container MegaMenu__container">
        <div className="MegaMenu__column">
          <h4 className="MegaMenu__column__title">{servicesT.software.title}</h4>
          <ul className="list-circle MegaMenu__column__list">
            <MegaMenuItem name={servicesT.software.website} to={`/${lang}/${services.software.name}/${services.software.pages[0]}/`} />
            <MegaMenuItem name={servicesT.software.webshop} to={`/${lang}/${services.software.name}/${services.software.pages[1]}/`} />
            {/* <MegaMenuItem name={servicesT.software.blog} to={`/${lang}/${services.software.name}/${services.software.pages[2]}/`} /> */}
            <MegaMenuItem name={servicesT.software.webApp} to={`/${lang}/${services.software.name}/${services.software.pages[3]}/`} />
            <MegaMenuItem name={servicesT.software.desktopApp} to={`/${lang}/${services.software.name}/${services.software.pages[4]}/`} />
            <MegaMenuItem name={servicesT.software.mobileApp} to={`/${lang}/${services.software.name}/${services.software.pages[5]}/`} />
            <MegaMenuItem name={servicesT.software.support} to={`/${lang}/${services.software.name}/${services.software.pages[6]}/`} />
          </ul>
        </div>

        <div className="MegaMenu__column">
          <h4 className="MegaMenu__column__title">{servicesT.cloud.title}</h4>
          <ul className="list-circle MegaMenu__column__list">
            {/* <MegaMenuItem name={servicesT.cloud.domain} to={`/${lang}/`} /> */}
            <MegaMenuItem name={servicesT.cloud.hosting} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[0]}/`} />
            <MegaMenuItem name={servicesT.cloud.vps} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[1]}/`} />
            {/* <MegaMenuItem name={servicesT.cloud.server} to={`/${lang}/`} /> */}
            {/* <MegaMenuItem name={servicesT.cloud.ssl} to={`/${lang}/`} /> */}
            <MegaMenuItem name={servicesT.cloud.email} to={`/${lang}/${services.cloud.name}/${services.cloud.pages[2]}/`} />
          </ul>
        </div>

        <div className="MegaMenu__column">
          <h4 className="MegaMenu__column__title">{servicesT.marketing.title}</h4>
          <ul className="list-circle MegaMenu__column__list">
            <MegaMenuItem name={servicesT.marketing.websitePositioning} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[0]}/`} />
            <MegaMenuItem name={servicesT.marketing.seo} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[1]}/`} />
            <MegaMenuItem name={servicesT.marketing.advertisement} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[2]}/`} />
            <MegaMenuItem name={servicesT.marketing.copywriting} to={`/${lang}/${services.marketing.name}/${services.marketing.pages[3]}/`} />
          </ul>
        </div>

        <div className="MegaMenu__column">
          <h4 className="MegaMenu__column__title">{servicesT.additional.title}</h4>
          <ul className="list-circle MegaMenu__column__list">
            <MegaMenuItem name={servicesT.additional.translations} to={`/${lang}/${services.additional.name}/${services.additional.pages[0]}/`} />
            <MegaMenuItem name={servicesT.additional.law} to={`/${lang}/${services.additional.name}/${services.additional.pages[1]}/`} />
            {/* <MegaMenuItem name={servicesT.additional.office365} to={`/${lang}/`} /> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

MegaMenu.propTypes = {
  lang: PropTypes.string.isRequired,
  servicesT: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default MegaMenu;
