import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Navigation from "./Navigation";
import Lang from "./Lang";

import imgGetinweb from "../../images/common/getinweb.svg";
import imgHamburgerMenu from "../../images/common/hamburger-menu.svg";

const Header = ({ lang, pathname, t, servicesT, withoutLangChange }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const headerRef = useRef();

  useEffect(() => {
    const wrapper = document.querySelector(".wrapper");

    const onScroll = () => {
      const headerClass = headerRef.current.classList;
      const fixedClass = "Header--fixed";
      const showClass = "Header--show";

      if (window.scrollY > 100) {
        if (!headerClass.contains(fixedClass)) headerClass.add(fixedClass);
        if (!wrapper.classList.contains("wrapper--withPadding")) wrapper.classList.add("wrapper--withPadding");
      } else if (headerClass.contains(fixedClass)) {
        headerClass.remove(fixedClass);
        if (wrapper.classList.contains("wrapper--withPadding")) wrapper.classList.remove("wrapper--withPadding");
      }

      if (window.scrollY > 400) {
        if (!headerClass.contains(showClass)) headerClass.add(showClass);
      } else if (headerClass.contains(showClass)) headerClass.remove(showClass);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header ref={headerRef} className="Header">
      <div className="container Header__container">
        <Link className="link-flex" to={`/${lang}/`}>
          <img className="Header__logo" src={imgGetinweb} alt="Getinweb" />
        </Link>

        <div className="Header__right">
          <Navigation lang={lang} pathname={pathname} t={t} servicesT={servicesT} opened={mobileMenu} close={() => setMobileMenu(false)} />

          <Lang lang={lang} pathname={pathname} withoutLangChange={withoutLangChange} />

          <button onClick={() => setMobileMenu(true)} className="Header__hamburgerMenu">
            <img src={imgHamburgerMenu} alt="Menu" />
          </button>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  servicesT: PropTypes.object.isRequired,
  withoutLangChange: PropTypes.bool
};

export default Header;
