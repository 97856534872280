import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import OrderService from "../../services/OrderService";
import { getHrefs } from "../../utils/langService";

import imgBag from "../../images/common/bag.svg";

const OrderLeaf = ({ t, lang, quantity }) => {
  const [hidden, setHidden] = useState(false);

  const setAbandon = () => {
    OrderService.removeOrder();
    setHidden(true);
  };

  const { pages } = getHrefs(lang);

  return (
    <div className={`OrderLeaf ${hidden ? "OrderLeaf--hidden" : ""}`}>
      <div className="OrderLeaf__image">
        <img src={imgBag} alt="order" />
        <span className="OrderLeaf__quantity">{quantity}</span>
      </div>

      <div className="OrderLeaf__content">
        <p>{t.description}</p>

        <div className="OrderLeaf__links">
          <Link to={`/${lang}/${pages[7]}/`} className="OrderLeaf__link">
            {t.goToOrder}
          </Link>
          <button className="OrderLeaf__link" onClick={setAbandon}>
            {t.abandon}
          </button>
        </div>
      </div>
    </div>
  );
};

OrderLeaf.propTypes = {
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired
};

export default OrderLeaf;
