import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { getHrefs } from "../../utils/langService";
import MegaMenu from "./MegaMenu";

import imgClose from "../../images/common/close-dark.svg";

export const NavElem = ({ name, to, pathname, highlighted, ...attributes }) => (
  <li className="Navigation__elem" {...attributes}>
    <Link
      className={`Navigation__elem__link ${to === pathname ? "Navigation__elem__link--active" : ""} ${
        highlighted ? "Navigation__elem__link--highlighted" : ""
      }`}
      to={to}
    >
      {name}
    </Link>
  </li>
);

NavElem.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  highlighted: PropTypes.bool
};

const Navigation = ({ lang, pathname, t, servicesT, opened, close }) => {
  const [mobileMegaMenu, setMobileMegaMenu] = useState(false);

  const closeMobileMegaMenu = () => {
    setMobileMegaMenu(false);
    close();
  };

  const { pages } = getHrefs(lang);

  return (
    <nav className={`Navigation ${opened ? "Navigation--mobileOpen" : ""}`}>
      <div className="Navigation__close">
        <button onClick={close} className="Navigation__close__btn">
          <img src={imgClose} alt="Zamknij" />
        </button>
      </div>

      <ul className="Navigation__list">
        <NavElem name={t.nav.home} to={`/${lang}/`} pathname={pathname} />

        <li className="Navigation__elem Navigation__elem--services">
          <button className="Navigation__elem__services" onClick={() => setMobileMegaMenu(true)}>
            {t.nav.services}
          </button>

          <MegaMenu lang={lang} servicesT={servicesT} opened={mobileMegaMenu} close={closeMobileMegaMenu} />
        </li>

        {/* <NavElem name={t.nav.blog} to={`/pl/${getHrefs("pl").pages[2]}/`} pathname={pathname} /> */}
        <NavElem name={t.nav.about} to={`/${lang}/${pages[0]}/`} pathname={pathname} />
        <NavElem name={t.nav.contact} to={`/${lang}/${pages[3]}/`} pathname={pathname} />
        <NavElem name={t.nav.estimate} to={`/${lang}/${pages[4]}/`} pathname={pathname} highlighted />
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  lang: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  servicesT: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default Navigation;
